import axios from "axios";
import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions,
} from "react-query";
import { ApiPayloadType } from "../utils/utils";
import { useToastContext } from "../contexts/ToastProvider";
import { useAuthContext } from "../contexts/AuthProvider/AuthProvider";
import throttle from "lodash.throttle";

const defaultUrl = process.env.GATSBY_FUNCTION_APP_URL;

interface FetchFunction<T> {
  (args: any): Promise<T>;
}

export const useApiQuery = <T>(
  key: string,
  fetchFunction: FetchFunction<T>,
  options?: UseQueryOptions<T, unknown>,
) => {
  const throttledFetchFunction = throttle(fetchFunction, 1000); // 1000ms = 1 second

  const safeFetchFunction: FetchFunction<T> = (args) => {
    // Directly invoke the function, bypassing spread syntax
    const result = throttledFetchFunction(args);
    if (result === undefined) {
      // Handle undefined case, maybe return a default value or reject
      return Promise.reject("Throttled function returned undefined");
    }
    return result;
  };

  return useQuery<T, unknown>(key, safeFetchFunction, options);
};

export const useApiMutation = <T>(
  options?: UseMutationOptions<T, unknown, ApiPayloadType, unknown>,
) => {
  const { state: authState, logout } = useAuthContext();
  const { showToast } = useToastContext();

  const atlasToken = authState.atlasToken;

  const apiCall = async (data: ApiPayloadType) => {
    const endpoint = defaultUrl as string;
    if (!endpoint) {
      console.error(
        "GATSBY_FUNCTION_APP_URL environment variable is not set or not a string",
      );
      return null as unknown as T;
    }

    if (!atlasToken) {
      showToast({
        title: "Authentication Error",
        description: "Atlas token is not set",
        type: "error",
      });
      console.error("Atlas token is not set");
      return null as unknown as T;
    }

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${atlasToken}`,
      },
      timeout: 300000,
    };

    if (atlasToken) {
      try {
        const response = await axios.post<T>(endpoint, data, requestOptions);
        return response.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          logout(); // Logout user if the request is unauthorized
        }
        showToast({
          title: "API Error",
          description: error.toString(),
          type: "error",
        });
      }
    }
  };

  // Throttle the API call
  const throttledApiCall = throttle(apiCall, 1000);

  return useMutation<T, unknown, ApiPayloadType>(
    async (data: ApiPayloadType) => {
      const result = throttledApiCall(data);
      if (result === undefined) {
        console.log("Throttled function returned undefined");
        return Promise.reject("Throttled function returned undefined");
      }
      // Ensure the result is a Promise that resolves to a value of type T
      return result.then((value: T | null | undefined) => {
        if (value === null || value === undefined) {
          return Promise.reject("API call returned null or undefined");
        }
        return value;
      });
    },
    {
      onError: (error: any) => {
        // showToast({ title: 'API Error', description: error.toString(), type: 'error' });
        // console.error(error);
      },
      ...options,
    },
  );
};

export const useApiRequest = () => useApiMutation();
