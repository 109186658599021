import React, { createContext, useContext, useReducer } from "react";
import { MessageEntity, ApiPayloadType } from "../utils/utils";
import { useApiQuery, useApiMutation } from "../hooks/useApi";
import { useUserContext } from "./UserProvider";
import { useConversationContext } from "./ConversationProvider";
import { set } from "lodash";

interface MessageProviderProps {
  children: React.ReactNode;
}

interface MessageState {
  messages: MessageEntity[];
  isTyping: boolean;
  loadingMessages: boolean;
}

interface MessageContextType extends MessageState {
  setMessages: (
    messages:
      | MessageEntity[]
      | ((prevMessages: MessageEntity[]) => MessageEntity[]),
  ) => void;
  setIsTyping: (isTyping: boolean) => void;
  setLoadingMessages: (loadingMessages: boolean) => void;
  refetch: () => void;
}

// Define action types
type MessageAction =
  | {
      type: "SET_MESSAGES";
      payload:
        | MessageEntity[]
        | ((prevMessages: MessageEntity[]) => MessageEntity[]);
    }
  | { type: "SET_IS_TYPING"; payload: boolean }
  | { type: "SET_LOADING_MESSAGES"; payload: boolean }
  | { type: "GET_MESSAGES_SUCCESS"; payload: MessageEntity[] }; // <-- new action type

const initialState: MessageState = {
  messages: [],
  isTyping: false,
  loadingMessages: false,
};

// Reducer function
const messageReducer = (
  state: MessageState,
  action: MessageAction,
): MessageState => {
  switch (action.type) {
    case "SET_MESSAGES":
      return {
        ...state,
        messages:
          typeof action.payload === "function"
            ? action.payload(state.messages)
            : action.payload,
      };
    case "SET_IS_TYPING":
      return { ...state, isTyping: action.payload };
    case "SET_LOADING_MESSAGES":
      return { ...state, loadingMessages: action.payload };
    case "GET_MESSAGES_SUCCESS":
      return {
        ...state,
        loadingMessages: false, // set loading to false
        messages: action.payload.sort(
          (a, b) =>
            new Date(b.timestamp || 0).getTime() -
            new Date(a.timestamp || 0).getTime(),
        ),
      };
    default:
      return state;
  }
};


const MessageContext = createContext<MessageContextType | undefined>(undefined);

export const useMessageContext = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error("useMessageContext must be used within a MessageProvider");
  }
  return context;
};

export const MessageProvider: React.FC<MessageProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(messageReducer, initialState);
  const { userProfile } = useUserContext();
  const { activeConversation } = useConversationContext();
  const apiMutation = useApiMutation<ApiPayloadType>();
  const queryClient = useApiQuery(
    'messages', // query key
    async () => {
      const payload = {
        action: "GET_MESSAGES",
        payload: {
          userId: userProfile?.rowKey,
          conversationId: activeConversation?.rowKey
            ? activeConversation.rowKey
            : userProfile?.conversationId,
        },
      };
      const response = await apiMutation.mutateAsync(payload); 
      return response;
    },
    {
      enabled: !!userProfile.rowKey,
      onSuccess: (data: any) => { 
        const sortedData = data.sort((a: MessageEntity, b: MessageEntity) => {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        });
      
        setMessages(sortedData);
      },
      onError: (error) => {
        console.log('error', error);
      }
    }
  );

  const { data: message, refetch } = queryClient;


  const setMessages = (
    messages:
      | MessageEntity[]
      | ((prevMessages: MessageEntity[]) => MessageEntity[]),
  ) => {
    dispatch({ type: "SET_MESSAGES", payload: messages });
  };
  const setIsTyping = (isTyping: boolean) =>
    dispatch({ type: "SET_IS_TYPING", payload: isTyping });
  const setLoadingMessages = (loadingMessages: boolean) =>
    dispatch({ type: "SET_LOADING_MESSAGES", payload: loadingMessages });

    
  const value = {
    ...state,
    setMessages,
    setIsTyping,
    setLoadingMessages,
    refetch,
  };

  return (
      <MessageContext.Provider value={value}>
        {children}
      </MessageContext.Provider>
  );
};
