import crypto from "crypto";
import { icons } from "../components/Settings/Icon";
interface BaseEntity {
  partitionKey: string;
  rowKey: string;
  timestamp: string;
}

export interface ContextEntity extends BaseEntity {
  original: string;
  compressed: string;
  namespace: string;
  pineconeId: string;
}

export interface UserEntity extends BaseEntity {
  email: string;
  model: string;
  tokens: number;
  name: string;
  paymentLink?: string;
  paymentLinkId?: string;
  conversationId: string;
  theme?: string;
  jobs?: JobsEntity[];
}
export interface JobsEntity extends BaseEntity {
  status: string;
  conversation_id: string;
  message_id: string;
  user_id: string;
  blob_name?: string;
}

export interface TokenEntity extends BaseEntity {
  completionId: string;
  model: string;
  promptTokens: number;
  completionTokens: number;
  totalTokens: number;
  userId: string;
}

export interface ConversationEntity extends BaseEntity {
  characterId: string;
  title: string;
  userId: string;
  report?: string;
}

type IconType = keyof typeof icons;

export interface CharacterEntity extends BaseEntity {
  name: string;
  summary: string;
  reportTemplate?: string;
  icon: IconType;
  color: string;
  userId: string;
  refinement: string;
  welcome: string;
  role?: string;
}

export interface MessageEntity extends BaseEntity {
  jobId: string;
  userId: string;
  characterId: string;
  conversationId: string;
  sender: string;
  content: string;
  files?: string;
  status?: string;
  title?: string;
}

export interface ApiPayloadType {
  action: string;
  payload: any;
}

export const generateId = () => {
  const userKey = crypto.randomBytes(16).toString("hex");
  return userKey;
};

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

// Base Message Type
interface BaseMessage {
  job_id: string;
  user_id: string;
  timestamp: Date;
}

// File Upload Messaging Types
export interface FileUploadMessage extends BaseMessage {
  type: "FILE_UPLOAD";
  message_id: string;
  conversation_id: string;
  status:
    | "UPLOADING"
    | "EXTRACTING_TEXT"
    | "DIVIDING"
    | "GENERATING_SUMMARY"
    | "COMPLETED";
  percent_complete?: number;
}

// Form Processing Messaging Types
export interface FormProcessingMessage extends BaseMessage {
  type: "FORM_PROCESSING";
  conversation_id: string;
  status: "IDLE" | "ANALYZING" | "AWAITING_INPUT";
  percent_complete?: number;
}

export type JobMessage = FileUploadMessage | FormProcessingMessage;

export const getExponentialBackoffTime = (retryCount: number) => {
  return Math.pow(2, retryCount) * 1000 + Math.random() * 1000;
};

export const isRetryableError = (error: any) => {
  // Implement logic to determine if an error is retryable
  return true;
};

export interface FormOption {
  label: string;
  value: string;
}

// Define the structure for a form field
export interface FormField {
  type: string;
  label: string;
  apiName: string;
  value: string | boolean;
  options?: FormOption[];
  subfields?: FormField[];
  description?: string;
  placeholder?: string;
  additionalText?: string;
  prefix?: string;
}

// Define the structure for a form section
export interface FormSection {
  sectionTitle: string;
  sectionDescription: string;
  fields: FormField[];
}

// Define the structure for form data
export type FormData = FormSection[];

export interface GenericTableReport {
  tableLabel: string;
  tableApiName: string;
  formData: FormSection[];
  atlasToken: string;
  Id?: string;
}
