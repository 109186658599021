import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
} from "react";
import { JobMessage } from "../utils/utils";
import { useSocket } from "./SocketProvider";

interface JobsState {
  jobMessages: JobMessage[];
}

interface JobsContextProps extends JobsState {
  dispatch: React.Dispatch<JobAction>;
}

const initialState: JobsState = {
  jobMessages: [],
};

type JobAction =
  | { type: "ADD_JOB_MESSAGE"; payload: JobMessage }
  | { type: "UPDATE_JOB_MESSAGE"; payload: JobMessage }
  | { type: "REMOVE_JOB_MESSAGE"; payload: string };

let jobIdSet = new Set();

const jobsReducer = (state: JobsState, action: JobAction): JobsState => {
  console.log("jobsReducer called with state:", state, "and action:", action);

  switch (action.type) {
    case "ADD_JOB_MESSAGE":
      console.log("Entering 'ADD_JOB_MESSAGE' case");

      if (!jobIdSet.has(action.payload.job_id)) {
        console.log(
          `job_id ${action.payload.job_id} not found in set. Adding...`,
        );

        jobIdSet.add(action.payload.job_id);

        const newState = {
          ...state,
          jobMessages: [...state.jobMessages, action.payload],
        };
        console.log("New state after 'ADD_JOB_MESSAGE':", newState);

        return newState;
      } else {
        console.log(
          `job_id ${action.payload.job_id} already exists. Skipping addition.`,
        );
        return state;
      }

    case "UPDATE_JOB_MESSAGE":
      console.log("Entering 'UPDATE_JOB_MESSAGE'");

      const updatedState = {
        ...state,
        jobMessages: state.jobMessages.map((msg) =>
          msg.job_id === action.payload.job_id ? action.payload : msg,
        ),
      };

      console.log("New state after 'UPDATE_JOB_MESSAGE':", updatedState);
      return updatedState;

    case "REMOVE_JOB_MESSAGE":
      console.log("Entering 'REMOVE_JOB_MESSAGE' case");

      jobIdSet.delete(action.payload);

      const filteredState = {
        ...state,
        jobMessages: state.jobMessages.filter(
          (msg) => msg.job_id !== action.payload,
        ),
      };

      console.log(
        `Removed job_id ${action.payload}. New state:`,
        filteredState,
      );
      return filteredState;

    default:
      console.log("Default case reached. Returning current state.");
      return state;
  }
};

const JobsContext = createContext<JobsContextProps>({
  ...initialState,
  dispatch: () => {
    throw new Error("Tried using JobsContext dispatch outside of its provider");
  },
});

interface ContextProps {
  children: ReactNode;
}

export const JobsProvider: React.FC<ContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(jobsReducer, initialState);

  const { socket } = useSocket();

  // useEffect(() => {

  //   if (socket) {
  //     console.log("Socket is available:", socket);
  //     if (socket && state.jobMessages.length > 0) {
  //       state.jobMessages.forEach((jobMessage) => {
  //         if (jobMessage.type === "FILE_UPLOAD") {
  //           console.log("Subscribing to FILE_UPLOAD job", jobMessage.job_id);
  //           socket.emit(
  //             "subscribeUniversal",
  //             jobMessage.job_id,
  //             "FILE_UPLOAD",
  //             (response: any) => {
  //               if (response.error) {
  //                 console.error("Failed to subscribe:", response.error);
  //               } else {
  //                 console.log("Successfully subscribed");
  //               }
  //             },
  //           );
  //         }
  //       });
  //     }
  //   } else {
  //     console.log("Socket is not available");
  //   }

  // }, [socket, state.jobMessages]);

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== "production") {
  //     console.log(
  //       `[${new Date()}] JobsProvider: state changed: ${JSON.stringify(state)}`,
  //     );
  //   }
  // }, [state]);

  return (
    <JobsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </JobsContext.Provider>
  );
};

export const useJobsContext = (): JobsContextProps => {
  return useContext(JobsContext);
};
