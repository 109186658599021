export const ATLAS_TOKEN_EXCHANGE_URL =
  process.env.GATSBY_ATLAS_TOKEN_EXCHANGE_URL;
export const ATLAS_TOKEN_REFRESH_URL =
  process.env.GATSBY_ATLAS_TOKEN_REFRESH_URL;
export const APP = process.env.GATSBY_APP;
export const LOCAL_APP_URL = process.env.GATSBY_LOCAL_APP_URL;
export const STREAMING_APP_URL = process.env.GATSBY_STREAMING_APP_URL;
export const QUEUE_URL = process.env.GATSBY_QUEUE_URL;
export const FUNCTION_APP_URL = process.env.GATSBY_FUNCTION_APP_URL;

export const envVars = [
  ATLAS_TOKEN_EXCHANGE_URL,
  ATLAS_TOKEN_REFRESH_URL,
  APP,
  LOCAL_APP_URL,
  STREAMING_APP_URL,
  QUEUE_URL,
  FUNCTION_APP_URL,
];
