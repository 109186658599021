import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuthContext } from './AuthProvider/AuthProvider';

interface ContextProps {
  children: ReactNode;
}

interface SocketContextProps {
  socket: Socket | null;
  increment: () => void;
  decrement: () => void;
}

const SocketContext = createContext<SocketContextProps>({ 
  socket: null, 
  increment: () => {}, 
  decrement: () => {} 
});

export const SocketProvider: React.FC<ContextProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [count, setCount] = useState(0);
  const { state:authState } = useAuthContext();
  const atlasToken = authState.atlasToken;

  const socketUrl = process.env.GATSBY_STREAMING_APP_URL || '';

  const increment = useCallback(() => setCount(prevCount => prevCount + 1), []);
  const decrement = useCallback(() => setCount(prevCount => prevCount - 1), []);

  useEffect(() => {
    if (count > 0 && !socket) {
      const newSocket: Socket = io(socketUrl, {
        auth: {
          token: `Bearer ${atlasToken}`,
        },
      });
      setSocket(newSocket);
    }

    if (count === 0 && socket) {
      socket.close();
      setSocket(null);
    }

  }, [atlasToken, count, socket]);

  return (
    <SocketContext.Provider value={{ socket, increment, decrement }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextProps => useContext(SocketContext);