// AuthProvider.tsx

import React, { createContext, useReducer, useContext, useEffect } from "react";
import { AuthState, AuthAction } from "./types";
import { pca } from "./config";
import { fetchAzureToken, handleTokenExchange } from "./actions";

const initialState: AuthState = {
  azureToken: null,
  atlasToken: null,
  isLoading: false,
  error: null,
};

const AuthContext = createContext<{
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
  logout: () => void;
}>({
  state: initialState,
  dispatch: () => null,
  logout: () => null,
});

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "SET_AZURE_TOKEN":
      return { ...state, azureToken: action.payload, isLoading: false };
    case "SET_ATLAS_TOKEN":
      return { ...state, atlasToken: action.payload, isLoading: false };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload, isLoading: false };
    case "CLEAR_ALL":
      return { ...initialState };
    default:
      return state;
  }
};

let lastAzureTime: number | null = null;

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const currentTime = new Date().getTime();
    if (lastAzureTime && currentTime - lastAzureTime < 1000) {
      console.log(
        "Throttled: The function has been called within the last second.",
      );
      return;
    }
    lastAzureTime = currentTime;
    setTimeout(() => {
      fetchAzureToken(dispatch, pca);
    }, 500);
  }, []);

  useEffect(() => {
    if (state.azureToken && !state.atlasToken) {
      handleTokenExchange(state.azureToken, dispatch);
    }
  }, [state.azureToken, state.atlasToken]);

  const logout = () => {
    dispatch({ type: "CLEAR_ALL" });
    pca.logoutRedirect();
  };

  return (
    <AuthContext.Provider value={{ state, dispatch, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
