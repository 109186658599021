import React, { createContext, useState, useContext, useCallback, useEffect, useReducer } from 'react';

interface ToastContextType {
  toastMessage: ToastMessage;
  showToast: (message: ToastMessage) => void;
  hideToast: () => void;
}

export interface ToastMessage {
  title: string;
  description: string;
  type: string;
}

interface ToastProviderProps {
    children: React.ReactNode;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToastContext must be used within a ToastProvider");
  }
  return context;
};

const initialState: ToastMessage = { title: '', description: '', type: '' };

type Action = 
  | { type: 'SHOW_TOAST', payload: ToastMessage }
  | { type: 'HIDE_TOAST' };

const reducer = (state: ToastMessage, action: Action): ToastMessage => {
  switch (action.type) {
    case 'SHOW_TOAST':
      return action.payload;
    case 'HIDE_TOAST':
      return initialState;
    default:
      return state;
  }
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toastMessage, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log('ToastProvider rendered');
  }, []);

  const showToast = useCallback((message: ToastMessage) => {
    dispatch({ type: 'SHOW_TOAST', payload: message });
  }, []);

  const hideToast = useCallback(() => {
    dispatch({ type: 'HIDE_TOAST' });
  }, []);

  return (
    <ToastContext.Provider value={{ toastMessage, showToast, hideToast }}>
      {children}
    </ToastContext.Provider>
  );
};
