import React, { createContext, useState, useContext, useCallback } from 'react';

interface ConfirmationContextType {
  confirmationMessage: ConfirmationMessage;
  showConfirmation: (message: ConfirmationMessage) => void;
  hideConfirmation: () => void;
}

export interface ConfirmationMessage {
  title: string;
  description: string;
  onConfirm?: () => void;
}

interface ConfirmationProviderProps {
    children: React.ReactNode;
}

const ConfirmationContext = createContext<ConfirmationContextType | undefined>(undefined);

export const useConfirmationContext = () => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error("useConfirmationContext must be used within a ConfirmationProvider");
  }
  return context;
};

export const ConfirmationProvider = ({ children }: ConfirmationProviderProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState<ConfirmationMessage>({ title: '', description: '' });

  // Function to show the confirmation with a given message
  const showConfirmation = useCallback((message: ConfirmationMessage) => {
    console.log("Showing confirmation with message: ", message);
    setConfirmationMessage(message);
  }, []);
  

  // Function to hide the confirmation
  const hideConfirmation = useCallback(() => {
    setConfirmationMessage({ title: '', description: '' });
  }, []);

  return (
    <ConfirmationContext.Provider value={{ confirmationMessage, showConfirmation, hideConfirmation }}>
      {children}
    </ConfirmationContext.Provider>
  );
};
