import React, { createContext, useReducer, useContext } from "react";

interface MenuState {
  isMenuOpen: boolean;
  activePage: string;
  activeTitle: string;
  activeMenu: string; // Added activeMenu item to the state
}

interface MenuAction {
  type: "TOGGLE_MENU" | "SET_MENU" | "SET_PAGE" | "SET_TITLE" | "SET_MENU_VISIBILITY";
  payload?: boolean | string;
}

interface MenuContextType extends MenuState {
  toggleMenu: () => void;
  setActiveMenu: (value: string) => void;
  setIsMenuOpen: (value: boolean) => void;
  setActivePage: (value: string) => void;
  setActiveTitle: (value: string) => void;
}

interface MenuProviderProps {
  children: React.ReactNode;
}

const MenuContext = createContext<MenuContextType | undefined>(undefined);

const initialState: MenuState = {
  isMenuOpen: false,
  activePage: "HOME",
  activeTitle: "Atlas",
  activeMenu: "CONVERSATIONS",
};

function reducer(state: MenuState, action: MenuAction): MenuState {
  switch (action.type) {
    case "TOGGLE_MENU":
      return { ...state, isMenuOpen: !state.isMenuOpen };
    case "SET_MENU_VISIBILITY":
      return { ...state, isMenuOpen: action.payload as boolean };
    case "SET_MENU":
      return { ...state, activeMenu: action.payload as string };
    case "SET_PAGE":
      return { ...state, activePage: action.payload as string };
    case "SET_TITLE":
      return { ...state, activeTitle: action.payload as string };
    default:
      return state;
  }
}

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenuContext must be used within a MenuProvider");
  }
  return context;
};

export const MenuProvider = ({ children }: MenuProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleMenu = () => {
    dispatch({ type: "TOGGLE_MENU" });
  };

  const setIsMenuOpen = (value: boolean) => {
    dispatch({ type: "SET_MENU_VISIBILITY", payload: value });
  };

  const setActiveMenu = (value: string) => {
    dispatch({ type: "SET_MENU", payload: value });
  };

  const setActivePage = (value: string) => {
    dispatch({ type: "SET_PAGE", payload: value });
  };

  const setActiveTitle = (value: string) => {
    dispatch({ type: "SET_TITLE", payload: value });
  };

  return (
    <MenuContext.Provider
      value={{
        ...state,
        toggleMenu,
        setIsMenuOpen,
        setActiveMenu,
        setActivePage,
        setActiveTitle,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
