import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import "./src/styles/global.css";
import { AuthProvider } from "./src/contexts/AuthProvider/AuthProvider";
import { SocketProvider } from "./src/contexts/SocketProvider";
import { UserProvider } from "./src/contexts/UserProvider";
import { MenuProvider } from "./src/contexts/MenuProvider";
import { ToastProvider } from "./src/contexts/ToastProvider";
import { ConfirmationProvider } from "./src/contexts/ConfirmationProvider";
import { ConversationProvider } from "./src/contexts/ConversationProvider";
import { MessageProvider } from "./src/contexts/MessageProvider";
import { CharacterProvider } from "./src/contexts/CharacterProvider";
import { PanelProvider } from "./src/contexts/RightSidePanelProvider";
import { JobsProvider } from "./src/contexts/JobsProvider";
import { envVars } from "./src/utils/constants";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";

// if (process.env.NODE_ENV === "development") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.GATSBY_APPLICATIONINSIGHTS_CONNECTION_STRING,
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => {
  envVars.forEach((variable) => {
    if (!variable) {
      throw new Error(`The environment variable ${variable} is not set.`);
    }
  });

  return (
    <ToastProvider>
      <AuthProvider>
        <UserProvider>
          <SocketProvider>
            <QueryClientProvider client={queryClient}>
              <JobsProvider>
                <CharacterProvider>
                  <ConversationProvider>
                    <MessageProvider>
                      <MenuProvider>
                        <PanelProvider>
                          <ConfirmationProvider>{element}</ConfirmationProvider>
                        </PanelProvider>
                      </MenuProvider>
                    </MessageProvider>
                  </ConversationProvider>
                </CharacterProvider>
              </JobsProvider>
            </QueryClientProvider>
          </SocketProvider>
        </UserProvider>
      </AuthProvider>
    </ToastProvider>
  );
};
