import React, {useState, createContext, useContext} from 'react';
import { UserEntity } from '../utils/utils';

interface UserContextType {
  userProfile: UserEntity;
  setUserProfile: (value: UserEntity) => void;
  activeProfilePhoto: string;
  setActiveProfilePhoto: (value: string) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

interface AppProviderProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: AppProviderProps) => {
  const [userProfile, setUserProfile] = useState<UserEntity>({
    rowKey: "",
    partitionKey: "",
    timestamp: "",
    email: "",
    model: "",
    tokens: 0,
    conversationId: "",
    name: "",
  });
  const [activeProfilePhoto, setActiveProfilePhoto] = useState<string>("none");

  const value = {
    userProfile,
    setUserProfile,
    activeProfilePhoto,
    setActiveProfilePhoto,
  };

  return (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  );
};