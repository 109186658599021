// AuthActions.ts

import { AuthAction, AuthDispatch } from "./types";
import axios from "axios";
import { isRetryableError, getExponentialBackoffTime } from "../../utils/utils";

let lastExchangeTime: number | null = null;
export const handleTokenExchange = async (
  azureToken: string,
  dispatch: React.Dispatch<AuthAction>,
): Promise<void> => {
  const currentTime = new Date().getTime();

  // Check if the function was called within the last second
  if (lastExchangeTime && currentTime - lastExchangeTime < 1000) {
    console.log(
      "Throttled: The function has been called within the last second.",
    );
    return;
  }
  // Update the last called time
  lastExchangeTime = currentTime;
  console.log("Starting token exchange process...");

  const atlasTokenExchangeUrl =
    process.env.GATSBY_ATLAS_TOKEN_EXCHANGE_URL || "";

  if (!atlasTokenExchangeUrl) {
    console.error(
      "Atlas token exchange URL is missing. Aborting token exchange.",
    );
    return;
  }

  const options = {
    headers: {
      Authorization: `Bearer ${azureToken}`,
    },
    timeout: 5000, // 5 seconds
  };

  console.log(
    "Sending request to Atlas token exchange URL:",
    atlasTokenExchangeUrl,
  );

  try {
    const tokenResponse = await axios.post(
      atlasTokenExchangeUrl,
      { azureToken: azureToken },
      options,
    );

    console.log("Received token response from Atlas");

    if (!tokenResponse.data.atlasToken) {
      console.error(
        "No Atlas token found in the response. Aborting token storage and dispatch.",
      );
      return;
    }

    dispatch({
      type: "SET_ATLAS_TOKEN",
      payload: tokenResponse.data.atlasToken,
    });

    dispatch({ type: "SET_LOADING", payload: false });
    console.log("Token exchange process completed successfully.");
  } catch (error: any) {
    if (isRetryableError(error) && retryCount < MAX_RETRIES) {
      retryCount++;
      setTimeout(() => handleTokenExchange(azureToken, dispatch), getExponentialBackoffTime(retryCount));
    } else {
      dispatch({ type: "SET_ERROR", payload: error as string });
    }
  }
};

let lastAzureTime: number | null = null;
const MAX_RETRIES = 3;
let retryCount = 0;
export const fetchAzureToken = async (
  dispatch: AuthDispatch,
  pca: any,
): Promise<void> => {
  const currentTime = new Date().getTime();
  if (lastAzureTime && currentTime - lastAzureTime < 5000) {
    console.log(
      "Throttled: The function has been called within the last second.",
    );
    return;
  }
  lastAzureTime = currentTime;
  try {
    dispatch({ type: "SET_LOADING", payload: true }); // Set loading to true
    const account = pca.getAllAccounts()[0];
    if (account) {
      const response = await pca.acquireTokenSilent({
        scopes: ["openid", "profile"],
        account,
      });
      const fetchedAzureToken = response.idToken;
      dispatch({ type: "SET_AZURE_TOKEN", payload: fetchedAzureToken });
      console.log("Fetched Azure Token Silently");
    } else {
      pca.loginRedirect({
        scopes: ["openid", "profile"],
      });
    }
  } catch (error) {
    if (isRetryableError(error) && retryCount < MAX_RETRIES) {
      retryCount++;
      setTimeout(() => fetchAzureToken(dispatch, pca), getExponentialBackoffTime(retryCount));
    } else {
      dispatch({ type: "SET_ERROR", payload: error as string });
    }
  } finally {
    dispatch({ type: "SET_LOADING", payload: false }); // Set loading to false
  }
};

export const setAzureToken = (token: string): AuthAction => ({
  type: "SET_AZURE_TOKEN",
  payload: token,
});

export const setAtlasToken = (token: string): AuthAction => ({
  type: "SET_ATLAS_TOKEN",
  payload: token,
});

export const setLoading = (loading: boolean): AuthAction => ({
  type: "SET_LOADING",
  payload: loading,
});

export const setError = (error: string): AuthAction => ({
  type: "SET_ERROR",
  payload: error,
});

export const clearAll = (): AuthAction => ({
  type: "CLEAR_ALL",
});
