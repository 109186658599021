import React, { createContext, useReducer, useContext } from "react";

interface PanelState {
  isPanelOpen: boolean;
  activePanel: string;
}

interface PanelAction {
  type: "TOGGLE_PANEL" | "SET_PANEL_VISIBILITY" | "SET_ACTIVE_PANEL";
  payload?: boolean | string;
}

interface PanelContextType extends PanelState {
  togglePanel: () => void;
  setIsPanelOpen: (value: boolean) => void;
  setActivePanel: (value: string) => void;
}

interface PanelProviderProps {
  children: React.ReactNode;
}

const PanelContext = createContext<PanelContextType | undefined>(undefined);

const initialState: PanelState = {
  isPanelOpen: true,
  activePanel: "FORM",
};

function reducer(state: PanelState, action: PanelAction): PanelState {
  switch (action.type) {
    case "TOGGLE_PANEL":
      return { ...state, isPanelOpen: !state.isPanelOpen };
    case "SET_PANEL_VISIBILITY":
      return { ...state, isPanelOpen: action.payload as boolean };
    case "SET_ACTIVE_PANEL":
      return { ...state, activePanel: action.payload as string };
    default:
      return state;
  }
}

export const usePanelContext = () => {
  const context = useContext(PanelContext);
  if (!context) {
    throw new Error("usePanelContext must be used within a PanelProvider");
  }
  return context;
};

export const PanelProvider = ({ children }: PanelProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const togglePanel = () => {
    dispatch({ type: "TOGGLE_PANEL" });
  };

  const setIsPanelOpen = (value: boolean) => {
    dispatch({ type: "SET_PANEL_VISIBILITY", payload: value });
  };

  const setActivePanel = (value: string) => {
    dispatch({ type: "SET_ACTIVE_PANEL", payload: value });
  };

  return (
    <PanelContext.Provider
      value={{
        ...state,
        togglePanel,
        setIsPanelOpen,
        setActivePanel,
      }}
    >
      {children}
    </PanelContext.Provider>
  );
};
