import React, { ReactNode, createContext, useContext, useReducer, useEffect } from "react";
import { ConversationEntity } from "../utils/utils";
import _ from 'lodash';
import useReportSubscription, { ReportStatus } from '../hooks/useReportSubscriptions';
import { useSocket } from '../contexts/SocketProvider';  // Assuming you have a SocketProvider

interface ConversationContextType {
  state: State;
  conversations: ConversationEntity[];
  loadingConversations: boolean;
  activeConversation: ConversationEntity | null;
  setActiveConversation: (activeConversation: ConversationEntity) => void;
  setConversations: (conversations: ConversationEntity[]) => void;
  setLoadingConversations: (loadingConversations: boolean) => void;
}

interface ConversationProviderProps {
  children: ReactNode;
}

interface State {
  activeConversation: ConversationEntity | null;
  conversations: ConversationEntity[];
  loadingConversations: boolean;
}

type Action =
  | { type: "SET_ACTIVE_CONVERSATION"; payload: ConversationEntity | null }
  | { type: "SET_CONVERSATIONS"; payload: ConversationEntity[] }
  | { type: "SET_LOADING_CONVERSATIONS"; payload: boolean }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_ACTIVE_CONVERSATION":
      if (!_.isEqual(state.activeConversation, action.payload)) {
        return { ...state, activeConversation: action.payload };
      }
      return state;
    case "SET_CONVERSATIONS":
      if (!_.isEqual(state.conversations, action.payload)) {
        return { ...state, conversations: action.payload };
      }
      return state;
    case "SET_LOADING_CONVERSATIONS":
      if (state.loadingConversations !== action.payload) {
        return { ...state, loadingConversations: action.payload };
      }
      return state;
    default:
      return state;
  }
};

const initialState: State = {
  activeConversation: null,
  conversations: [],
  loadingConversations: true,
};

export const ConversationContext =
  createContext<ConversationContextType | undefined>(undefined);

export const useConversationContext = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error(
      "useConversationContext must be used within a ConversationProvider"
    );
  }
  return context;
};


export const ConversationProvider = ({ children }: ConversationProviderProps) => {
  type reducerStateType = {
    reducer: State
    initialState: State;
  }
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(reducer, initialState);

  const setActiveConversation = (activeConversation: ConversationEntity) =>
    dispatch({ type: 'SET_ACTIVE_CONVERSATION', payload: activeConversation });

  const setConversations = (conversations: ConversationEntity[]) =>
    dispatch({ type: 'SET_CONVERSATIONS', payload: conversations });

  const setLoadingConversations = (loadingConversations: boolean) =>
    dispatch({ type: 'SET_LOADING_CONVERSATIONS', payload: loadingConversations });


  const conversations = state.conversations;
  const loadingConversations = state.loadingConversations;
  const activeConversation = state.activeConversation;


  // Include the state and the new functions in your value
  const value = {
    state,
    conversations,
    loadingConversations,
    activeConversation,
    setActiveConversation,
    setConversations,
    setLoadingConversations,
  };

  return (
    <ConversationContext.Provider value={value}>
      {children}
    </ConversationContext.Provider>
  );
};
