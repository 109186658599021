import React, { createContext, useContext, useReducer } from "react";
import { CharacterEntity } from "../utils/utils";
import _ from 'lodash';

interface CharacterContextType {
  state: State;
  characters: CharacterEntity[];
  activeCharacter: CharacterEntity;
  setActiveCharacter: (activeCharacter: CharacterEntity) => void;
  setCharacters: (characters: CharacterEntity[]) => void;
}

interface CharacterProviderProps {
  children: React.ReactNode;
}

interface State {
  activeCharacter: CharacterEntity
  characters: CharacterEntity[];
}

type Action =
  | { type: "SET_ACTIVE_CHARACTER"; payload: CharacterEntity }
  | { type: "SET_CHARACTERS"; payload: CharacterEntity[] };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_ACTIVE_CHARACTER":
      if (!_.isEqual(state.activeCharacter, action.payload)) {
        return { ...state, activeCharacter: action.payload };
      }
      return state;
    case "SET_CHARACTERS":
      if (!_.isEqual(state.characters, action.payload)) {
        return { ...state, characters: action.payload };
      }
      return state;
    default:
      return state;
  }
};

const initialState: State = {
  activeCharacter: {
    rowKey: "00f8df3d3e0b73cc53b750c3d0ace704",
    partitionKey: "Characters",
    name: "Atlas",
    summary: "",
    timestamp: "",
    icon: "FiStar",
    color: "#FACC15",
    userId: "",
    refinement: "",
    welcome: "",
  },
  characters: [],
};

export const CharacterContext =
  createContext<CharacterContextType | undefined>(undefined);

export const useCharacterContext = () => {
  const context = useContext(CharacterContext);
  if (!context) {
    throw new Error(
      "useCharacterContext must be used within a CharacterProvider"
    );
  }
  return context;
};

export const CharacterProvider = ({
  children,
}: CharacterProviderProps) => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(reducer, initialState);

  const setActiveCharacter = (activeCharacter: CharacterEntity) =>
    dispatch({ type: "SET_ACTIVE_CHARACTER", payload: activeCharacter });

  const setCharacters = (characters: CharacterEntity[]) =>
    dispatch({ type: "SET_CHARACTERS", payload: characters });

  const characters = state.characters;
  const activeCharacter = state.activeCharacter;

  const value = {
    state,
    characters,
    activeCharacter,
    setActiveCharacter,
    setCharacters,
  };

  return (
    <CharacterContext.Provider value={value}>
      {children}
    </CharacterContext.Provider>
  );
};